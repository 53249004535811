// eslint-disable-next-line no-unused-vars
const medical = [
    {
        type: 'input',
        name: 'patient_number',
        label: '住院号',
        rules: [
            {required: true, message: '请输入住院号', trigger: 'blur'},
        ],
        main: true,
    },
    {
        type: 'radio',
        name: 'sex',
        label: '患者性别',
        rules: [
            {required: true, message: '请选择患者性别', trigger: 'change'},
        ],
        options: [{
            label: '男',
            value: 1,
        }, {
            label: '女',
            value: 2
        },]
    },
    {
        type: 'input',
        name: 'age',
        label: '患者年龄',
        rules: [
            {required: true, message: '请输入患者年龄', trigger: 'blur'},
        ],
    },
    {
        type: 'input',
        name: 'height',
        label: '患者体重',
        rules: [
            {required: true, message: '请输入患者体重', trigger: 'blur'},
        ],
    },
    {
        type: 'radio',
        name: 'is_referral',
        label: '是否转诊',
        rules: [
            {required: true, message: '请选择是否转诊', trigger: 'change'},
        ],
        hasInput: true,
        slotName: 'office',
        slotLabel: '科室',

        options: [{
            label: '否',
            value: 0,
        }, {
            label: '是',
            value: 1,
        },]
    },
    {
        type: 'hidden',
        name: 'office',
    },
    {
        type: 'date',
        name: 'admission_time',
        label: '入院时间',
        rules: [
            {required: true, message: '请选择入院时间', trigger: 'change'}
        ],
    },
    {
        type: 'textarea',
        name: 'previous_hostory',
        label: '既往史',
        // rules: [
        //   {required: true, message: '请输入既往史', trigger: 'blur'}
        // ],
    },
    {
        type: 'radio',
        name: 'hypertension_hostory',
        label: '是否有高血压病史',
        rules: [
            {required: true, message: '请选择是否有高血压病史', trigger: 'change'}
        ],
        options: [{
            label: '否',
            value: 0,
        }, {
            label: '是',
            value: 1,
        },]
    },


    {
        type: 'check',
        name: 'diagnosis_admission',
        label: '入院诊断 ',
        rules: [
            {type: 'array', required: true, message: '请至少选择一个入院诊断', trigger: 'change'}
        ],
        options: [
            {
                label: 'DebakeyⅠ型',
            }, {
                label: 'Stanford A型',
            }, {
                label: 'DebakeyⅡ型',
            }, {
                label: 'Stanford B型',
            }, {
                label: 'DebakeyⅢ型 ',
            },]
    },

    {
        type: 'input',
        name: 'other_admission',
        label: '其他诊断结果',
    },
    {
        type: 'textarea',
        name: 'sympotms_signs',
        label: '症状体征',
    },
    {
        type: 'radio',
        name: 'is_chest_abdomen_back_pain',
        label: '是否胸腹或背部疼痛',
        rules: [
            {required: true, message: '请选择是否胸腹或背部疼痛', trigger: 'change'}
        ],
        options: [{
            label: '否',
            value: 0,
        }, {
            label: '是',
            value: 1,
        },]
    },
    // {
    //   type: 'input',
    //   name: 'duration_of_pain',
    //   label: '疼痛持续时间',
    //   placeholder: '请输入疼痛持续时间',
    // },
    {
        type: 'input',
        name: 'hr',
        label: '心率HR(次/分)(注：没有填写＇无＇)',
        placeholder: '请输入初始检查结果',
        rules: [
            {required: true, message: '请输入初始检查结果', trigger: 'blur'}
        ],
    },
    {
        type: 'input',
        name: 'bp',
        label: '血压BP(mmHg)(注：没有填写＇无＇)',
        placeholder: '请输入初始检查结果',
        rules: [
            {required: true, message: '请输入初始检查结果', trigger: 'blur'}
        ],
    },
    {
        type: 'input',
        name: 'temperature',
        label: '体温T(℃)(注：没有填写＇无＇)',
        placeholder: '请输入初始检查结果',
        rules: [
            {required: true, message: '请输入初始检查结果', trigger: 'blur'}
        ],
    },
    {
        type: 'input',
        name: 'pulse',
        label: '脉搏P（次/分）(注：没有填写＇无＇)',
        placeholder: '请输入初始检查结果',
        rules: [
            {required: true, message: '请输入初始检查结果', trigger: 'blur'}
        ],
    },
    {
        type: 'input',
        name: 'breathe',
        label: '呼吸R（次/分）(注：没有填写＇无＇)',
        placeholder: '请输入初始检查结果',
        rules: [
            {required: true, message: '请输入初始检查结果', trigger: 'blur'}
        ],
    },
    // {
    //   type: 'input',
    //   name: 'd_dimer',
    //   label: 'D-二聚体（ug/L）',
    //   placeholder: '请输入初始检查结果',
    // },
    // {
    //   type: 'radio',
    //   name: 'is_operation',
    //   label: '是否手术',
    //   options: [{
    //     label: '否',
    //     value: 0,
    //   }, {
    //     label: '是',
    //     value: 1,
    //   },]
    // },
    // {
    //   type: 'date',
    //   name: 'operation_time',
    //   label: '手术时间',
    //   rules: [
    //     { required: true, message: '请选择日期', trigger: 'change'}
    //   ],
    // },
    {
        type: 'radio',
        name: 'is_operation',
        label: '是否手术',
        options: [{
            label: '否',
            value: 0,
        }, {
            label: '是',
            value: 1,
        },],
        rules: [
            {required: true, message: '请选择是否手术', trigger: 'change'}
        ],
    },
    {
        type: 'date',
        name: 'operation_time',
        label: '手术时间',
        placeholder: '请输入手术时间',
        parent: true,
        parent_name: 'is_operation',
        rules: [
            {required: true, message: '请选择日期', trigger: 'change'}
        ],
    },
]
const prescribed = [
    {
        type: 'date',
        name: 'start_time',
        label: '使用时间',
        rules: [
            {required: true, message: '请选择使用时间', trigger: 'change'},
        ]
    },
    {
        type: 'date',
        name: 'end_time',
        label: '结束时间',
        rules: [
            {required: true, message: '请选择结束时间', trigger: 'change'},
        ]
    },
    {
        type: 'radio',
        name: 'method',
        label: '使用方法',
        options: [{
            label: '持续不间断使用',
            value: 0,
        }, {
            label: '持续间断使用',
            value: 1,
        },],
        rules: [
            {required: true, message: '请选择使用方法', trigger: 'change'},
        ]
    },
    {
        type: 'radio',
        name: 'is_use_load_dose',
        label: '是否使用负荷剂量',
        options: [{
            label: '是',
            value: 0,
        }, {
            label: '否',
            value: 1,
        },],
        rules: [
            {required: true, message: '请选择使用方法', trigger: 'change'},
        ]
    },

    {
        type: 'input',
        name: 'total',
        label: '使用总量',
        placeholder: '请输入使用总量（mg）',
        rules: [
            {required: true, message: '请输入使用总量（mg）', trigger: 'blur'},
        ],

    },
    {
        type: 'input',
        name: 'dose_range',
        label: '使用剂量范围',
        placeholder: '请输入使用剂量范围（mg/h）',
        sHolder: '最小剂量（mg/h）',
        eHolder: '最大剂量（mg/h）',
        double: true,
        rules: [
            {required: true, message: '请输入使用剂量范围（mg/h）', trigger: 'blur'},
        ]
    },
    {
        type: 'check',
        name: 'combination_medication',
        label: '合并用药情况 ',
        rules: [
            {type: 'array', required: true, message: '请选择合并用药情况', trigger: 'change'}
        ],
        options: [
            {
                label: '乌拉地尔',
            }, {
                label: '硝普钠',
            }, {
                label: '地尔硫卓',
            }, {
                label: '右美托咪定 ',
            },
            {
                label: '米力农 ',
            },
            {
                label: '左西孟旦 ',
            }
        ]

    },


]
//病程记录
const course = [


    {
        type: 'radio',
        name: 'patient_transition',
        label: '患者转归',
        rules: [
            {required: true, message: '请选择患者转归情况', trigger: 'change'}
        ],
        options: [{
            label: '好转',
            value: 0,
        }, {
            label: '未愈',
            value: 1,
        }, {
            label: '死亡',
            value: 2,

        },]
    },
    {
        type: 'input',
        name: 'hr',
        label: '心率HR(次/分)',
        placeholder: '请输入初始检查结果',
        sHolder: '开始用药',
        eHolder: '结束用药',
        double: true,
        inputType: 'number',
        rules: [
            {required: true, message: '请输入初始检查结果', trigger: 'blur'}
        ],
    },
    {
        type: 'input',
        name: 'bp',
        label: '血压BP(mmHg)',
        placeholder: '请输入初始检查结果',
        sHolder: '开始用药',
        eHolder: '结束用药',
        double: true,

        rules: [
            {required: true, message: '请输入初始检查结果', trigger: 'blur'}
        ],
    },
    {
        type: 'input',
        name: 'temperature',
        label: '体温T(℃)   (注：没有填写＇无＇)\'',
        placeholder: '请输入初始检查结果',
        sHolder: '开始用药',
        eHolder: '结束用药',
        double: true,
        rules: [
            {required: true, message: '请输入初始检查结果', trigger: 'blur'}
        ],
    },
    // {
    //   type: 'input',
    //   name: 'pulse',
    //   label: '血肌酐（umol/L）',
    //   placeholder: '请输入初始检查结果',
    // },
    // {
    //   type: 'input',
    //   name: 'breathe',
    //   label: '呼吸R（次/分）',
    //   placeholder: '请输入初始检查结果',
    // },
    {
        type: 'input',
        name: 'serum_creatinine',
        label: '血肌酐(umol/L)  (注：没有填写＇无＇)',
        sHolder: '开始用药',
        eHolder: '结束用药',
        placeholder: '请输入初始检查结果',
        double: true,
        rules: [
            {required: true, message: '请输入初始检查结果', trigger: 'blur'}
        ],
    },
    {
        type: 'input',
        name: 'Bnp',
        label: 'BNP/NT-ProBNP   (注：没有填写＇无＇)',
        placeholder: '请输入初始检查结果',
        sHolder: '开始用药',
        eHolder: '结束用药',
        double: true,
        rules: [
            {required: true, message: '请输入初始检查结果', trigger: 'blur'}
        ],
    },
    {
        type: 'input',
        name: 'TnI',
        label: '肌钙蛋白I   (注：没有填写＇无＇)',
        placeholder: '请输入初始检查结果',
        sHolder: '开始用药',
        eHolder: '结束用药',
        double: true,
        rules: [
            {required: true, message: '请输入初始检查结果', trigger: 'blur'}
        ],
    },
    {
        type: 'radio',
        name: 'is_adverse_reaction',
        label: '不良反应',
        rules: [
            {required: true, message: '请选择不良反应', trigger: 'change'}
        ],
        options: [{
            label: '无',
            value: 0
        }, {
            label: '有',
            value: 1
        },]
    },
    {
        type: 'input',
        name: 'detail',
        label: '',
        placeholder: '请描述具体情况及措施'
    },
]

export {
    medical,
    prescribed,
    course
}
